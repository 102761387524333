<template>
    <div>
        <div v-if="messageVariables.length" class="form-group col-12 col-lg-6">
            <button
                class="btn btn-info waves-effect waves-light mr-1"
                @click="showMessageVariables = !showMessageVariables"
            >
                {{ variablesButtonText }}
            </button>
            <variables-table
                v-if="showMessageVariables"
                :variables="messageVariables"
            />
        </div>
        <div class="form-group col-12">
            <div class="d-flex justify-content-between">
                <label for="message_template"> SMS Hebrew template </label>
                <div
                    :style="{
                        color: isTooLong('hebrew') ? 'red' : 'green'
                    }"
                >
                    Characters: {{ getLength('hebrew') }}/{{ maxCharacters }}
                </div>
            </div>
            <textarea
                id="expiration_date"
                v-model="product.message_template.hebrew"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isTooLong('hebrew') }"
                rows="4"
                :maxlength="maxCharacters"
            />
        </div>
        <div class="form-group col-12">
            <div class="d-flex justify-content-between">
                <label for="message_template"> SMS Arabic template </label>
                <div
                    :style="{
                        color: isTooLong('arabic') ? 'red' : 'green'
                    }"
                >
                    Characters: {{ getLength('arabic') }}/{{ maxCharacters }}
                </div>
            </div>
            <textarea
                id="expiration_date"
                v-model="product.message_template.arabic"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isTooLong('arabic') }"
                rows="4"
                :maxlength="maxCharacters"
            />
        </div>
        <div class="form-group col-12">
            <div class="d-flex justify-content-between">
                <label for="message_template"> SMS Russian template </label>
                <div
                    :style="{
                        color: isTooLong('russian') ? 'red' : 'green'
                    }"
                >
                    Characters:
                    {{ getLength('russian') }}/{{ maxCharacters }}
                </div>
            </div>
            <textarea
                id="expiration_date"
                v-model="product.message_template.russian"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isTooLong('russian') }"
                rows="4"
                :maxlength="maxCharacters"
            />
        </div>
    </div>
</template>

<script>
import VariablesTable from './VariablesTable';

export default {
    components: {
        VariablesTable
    },

    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            showMessageVariables: false,
            maxCharacters: 990
        };
    },

    computed: {
        messageVariables() {
            const variables = [
                {
                    formValue: '$$patientID$$',
                    formLabel: 'תעודת זהות'
                },
                {
                    formValue: '$$patientName$$',
                    formLabel: 'שם מלא'
                },
                {
                    formValue: '$$patientPhone$$',
                    formLabel: 'מספר טלפון'
                }
            ];

            if (
                this.product.form_values &&
                this.product.form_values.values &&
                this.product.form_values.values.length
            ) {
                variables.push(...this.product.form_values.values);
            }

            return variables;
        },

        variablesButtonText() {
            return !this.showMessageVariables
                ? 'Show available variables'
                : 'Hide available variables';
        }
    },

    methods: {
        isTooLong(language) {
            return (
                this.product.message_template[language]?.length >
                this.maxCharacters
            );
        },

        getLength(language) {
            return this.product.message_template[language]?.length || 0;
        }
    }
};
</script>
