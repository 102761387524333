<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <h5> Selected Product </h5>
                            <b-form-select
                                v-model="selectedProduct"
                                :options="productsToDisplay"
                                text-field="name"
                                value-field="id"
                            />
                        </div>
                        <div v-if="productToUpdate.id" class="mt-4">
                            <edit-product
                                :product-to-update="productToUpdate"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@config';
import EditProduct from '@components/products/EditProduct';

export default {
    page: {
        title: 'Products',
        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        EditProduct
    },

    data() {
        return {
            title: 'Products',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Products',
                    active: true
                }
            ],
            products: [],
            selectedProduct: null,
            productToUpdate: {}
        };
    },

    computed: {
        productsToDisplay() {
            return this.products.filter(
                product =>
                    product.eng_name !== 'Saxenda®' &&
                    product.eng_name !== 'Wegovy®'
            );
        }
    },

    watch: {
        async selectedProduct() {
            if (!this.selectedProduct) {
                return;
            }

            this.productToUpdate = await this.getProduct(this.selectedProduct);
        }
    },

    async mounted() {
        await this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            getProduct: 'products/show'
        }),

        async fetchProducts() {
            this.products = await this.getProducts();

            this.selectedProduct = this.products.length
                ? this.products[0].id
                : null;
        }
    }
};
</script>
