<template>
    <div>
        <ul class="nav nav-pills navtab-bg nav-justified mb-3">
            <li v-for="type in types" :key="type" class="nav-item">
                <a
                    href="#"
                    data-toggle="tab"
                    aria-expanded="false"
                    class="nav-link"
                    :class="{
                        active: activeType === type
                    }"
                    @click.prevent="activeType = type"
                >
                    {{ type }}
                </a>
            </li>
        </ul>
        <div v-if="pdfsToUpdate" class="table-responsive">
            <products-draggable-list
                :key="pdfsToUpdate.length + '_' + productId"
                :show-color-box="true"
                :items="pdfsToUpdate"
                :item-type="'pdf'"
                @element-moved="onElementMove"
                @delete-element="onDeleteElement"
            />
        </div>
        <div class="form-group col-12">
            <label for="pdfName">PDF Name</label>
            <input
                id="pdfName"
                v-model="pdf.name"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': $v.pdf.name.$error
                }"
            />
        </div>
        <div class="form-group col-12">
            <label for="pdfDropzone">PDF File</label>
            <vue-dropzone
                id="pdfDropzone"
                ref="pdfDropzone"
                :options="pdfDropzoneOptions"
                class="h-100 d-flex justify-content-center align-items-center"
                @vdropzone-complete="uploadPDF($event)"
            />
        </div>
        <div class="form-group col-12">
            <label for="pdfLink">PDF Link</label>
            <input
                id="pdfLink"
                v-model="pdf.url"
                type="text"
                class="form-control"
            />
        </div>
        <div
            v-if="loggedUser && loggedUser.role === 'admin'"
            class="form-group col-12"
        >
            <label for="expiration_date">Expiration date</label>
            <input
                id="expiration_date"
                v-model="pdf.expiration_date"
                type="date"
                class="form-control"
                placeholder="Expiration date"
                max="3000-01-01"
            />
        </div>
        <div class="d-flex flex-wrap">
            <div class="form-group col-12 d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="addPdfHandler"
                >
                    Add PDF
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import config from '@config';
import ProductsDraggableList from './ProductsDraggableList';
import DropzoneMixin from '@components/mixins/dropzone.mixin';

export default {
    components: {
        ProductsDraggableList
    },

    mixins: [DropzoneMixin],

    props: {
        productId: {
            type: String,
            required: false,
            default: ''
        },
        pdfs: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    validations: {
        pdf: {
            name: {
                required
            },
            url: {
                required
            }
        }
    },

    data() {
        return {
            pdfsToUpdate: [],
            pdf: {},
            types: ['doctor', 'nurse', 'patient'],
            activeType: 'doctor'
        };
    },

    computed: {
        ...mapGetters({ loggedUser: 'auth/currentUser' }),

        pdfDropzoneOptions() {
            const options = {
                url: config.apiUrl + '/products/pdf/store',
                withCredentials: true,
                thumbnailWidth: 150,
                maxFilesize: 300,
                maxFiles: 1,
                acceptedFiles: '.pdf',
                dictDefaultMessage: "Upload New PDF <i class='fe-upload'></i>",
                addRemoveLinks: true
            };

            return options;
        }
    },

    watch: {
        pdfs() {
            this.pdfsToUpdate = [
                ...this.pdfs.filter(pdf => pdf.type === this.activeType)
            ];
        },
        activeType() {
            this.pdfsToUpdate = [
                ...this.pdfs.filter(pdf => pdf.type === this.activeType)
            ];
        }
    },

    mounted() {
        this.pdfsToUpdate = [...this.pdfs];
    },

    methods: {
        ...mapActions({
            addPdf: 'products/addPdf',
            deletePdf: 'products/deletePdf',
            changePdfOrder: 'products/changePdfOrder'
        }),

        clearPdf() {
            this.pdf = {};
            this.$v.pdf.$reset();

            const pdf = this.$refs.pdfDropzone;
            if (pdf.dropzone.files.length) {
                pdf.dropzone.removeFile(pdf.dropzone.files[0]);
            }
        },

        async addPdfHandler() {
            try {
                this.$v.pdf.$touch();

                if (this.$v.pdf.$invalid) {
                    return;
                }

                const newPdf = await this.addPdf({
                    productId: this.productId,
                    pdf: { ...this.pdf, type: this.activeType }
                });

                this.clearPdf();
                this.pdfsToUpdate = [...this.pdfsToUpdate, newPdf];

                this.$emit('update-pdfs', [...this.pdfs, newPdf]);

                this.$bvToast.toast('Pdf added successfully!', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                let message = '';

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                } else {
                    message = 'Something went wrong!';
                }

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async onElementMove(items) {
            const orderedArray = items.map((item, index) => {
                return { ...item, order: index };
            });

            try {
                await this.changePdfOrder({
                    orderedPdfs: orderedArray,
                    productId: this.productId
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async onDeleteElement(id) {
            try {
                await this.deletePdf(id);

                const index = this.pdfsToUpdate.findIndex(
                    item => item.id === id
                );

                if (index !== -1) {
                    this.pdfsToUpdate.splice(index, 1);
                }

                this.$emit(
                    'update-pdfs',
                    this.pdfs.filter(pdf => pdf.id !== id)
                );
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        uploadPDF(e) {
            const ref = this.$refs.pdfDropzone;

            if (ref.dropzone.files.length > 1) {
                ref.dropzone.removeFile(ref.dropzone.files[0]);
            }

            if (!e.xhr) {
                return;
            }

            this.pdf.url = e.xhr.response;
        }
    }
};
</script>
