<template>
    <b-modal
        id="modalCheckboxEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title"> Edit checkbox </template>
        <div class="custom-modal-text text-left">
            <div class="form-group">
                <label for="title"> Title </label>
                <input
                    id="title"
                    v-model="data.title"
                    type="text"
                    class="form-control"
                    placeholder="Title"
                />
            </div>
            <div class="form-group">
                <label for="title"> Type </label>
                <b-form-select
                    v-model="data.type"
                    :options="['PDF', 'Video']"
                    placeholder="Type"
                    @change="data.files = []"
                />
            </div>
            <div class="form-group">
                <label for="title"> Files </label>
                <multiselect
                    v-model="data.files"
                    :options="checkboxFiles"
                    label="name"
                    track-by="name"
                    :multiple="true"
                    placeholder="Files"
                />
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitCheckboxDataHandler"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    props: {
        product: {
            type: Object,
            required: true
        },
        checkbox: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            data: {}
        };
    },

    computed: {
        checkboxFiles() {
            const files =
                this.data.type === 'PDF'
                    ? this.product.pdfs
                    : this.product.videos;

            return files || [];
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalCheckboxEdit');
            this.$emit('hidden');
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.checkbox) {
                return (this.data = { ...this.checkbox });
            }

            this.data = {};
        },

        submitCheckboxDataHandler() {
            this.$emit('update-checkbox', this.data);
            this.$bvModal.hide('modalCheckboxEdit');
        }
    }
};
</script>
