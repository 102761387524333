<template>
    <div>
        <div class="form-group col-12">
            <label for="result_image">Product image</label>
            <vue-dropzone
                id="preview_image"
                ref="previewImage"
                :options="dropzoneOptions"
                class="h-100 d-flex justify-content-center align-items-center"
                @vdropzone-complete="changeImage($event, 'preview')"
            />
        </div>
        <div class="form-group col-12">
            <label for="result_image">Result image</label>
            <vue-dropzone
                id="result_image"
                ref="resultImage"
                :options="dropzoneOptions"
                class="h-100 d-flex justify-content-center align-items-center"
                @vdropzone-complete="changeImage($event, 'result')"
            />
        </div>
    </div>
</template>

<script>
import config from '@config';
import DropzoneMixin from '@components/mixins/dropzone.mixin';

export default {
    mixins: [DropzoneMixin],

    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            dropzoneRelativePath: '/products/image/store'
        };
    },

    watch: {
        product() {
            this.buildRefImage();
        }
    },

    methods: {
        changeImage(e, type) {
            const image =
                type === 'preview'
                    ? this.$refs.previewImage
                    : this.$refs.resultImage;

            if (image.dropzone.files.length > 1) {
                image.dropzone.removeFile(image.dropzone.files[0]);
            }

            if (!e.xhr) {
                return;
            }

            const response = JSON.parse(e.xhr.response);

            if (type === 'preview') {
                this.product.preview_image = response;
            } else {
                this.product.result_image = response;
            }
        },

        clearImage() {
            const { previewImage, resultImage } = this.$refs;

            if (previewImage && previewImage.dropzone.files.length) {
                previewImage.dropzone.removeFile(
                    previewImage.dropzone.files[0]
                );
            }

            if (resultImage && resultImage.dropzone.files.length) {
                resultImage.dropzone.removeFile(resultImage.dropzone.files[0]);
            }
        },

        async buildRefImage() {
            this.clearImage();

            if (!this.product.id) {
                return;
            }

            const resultImage = this.product.result_image;
            const previewImage = this.product.preview_image;

            if (resultImage.id) {
                await this.$nextTick();

                const backgroundImage = {
                    size: 0,
                    name: 'Result Image',
                    type: 'image/png'
                };

                this.$refs.resultImage.manuallyAddFile(
                    backgroundImage,
                    this.getImageFullPath(resultImage)
                );
            }

            if (previewImage.id) {
                await this.$nextTick();

                const backgroundImage = {
                    size: 0,
                    name: 'Product Image',
                    type: 'image/png'
                };

                this.$refs.previewImage.manuallyAddFile(
                    backgroundImage,
                    this.getImageFullPath(previewImage)
                );
            }
        },

        getImageFullPath(image) {
            return `${config.mediaUrl}/images/products/${image.date}/${image.id}.${image.extension}`;
        }
    }
};
</script>
