<template>
    <div>
        <b-form-checkbox
            v-model="allowDoctor"
            class="my-2"
            @change="updatePermissions"
        >
            Doctor
        </b-form-checkbox>
        <b-form-checkbox
            v-model="allowNutrition"
            class="my-2"
            @change="updatePermissions"
        >
            Nutrition
        </b-form-checkbox>
        <b-form-checkbox
            v-model="allowPharmacist"
            class="my-2"
            @change="updatePermissions"
        >
            Pharmacist
        </b-form-checkbox>
        <b-form-checkbox
            v-model="allowNurse"
            class="my-2"
            @change="updatePermissions"
        >
            Nurse
        </b-form-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            allowDoctor: true,
            allowPharmacist: true,
            allowNutrition: true,
            allowNurse: true
        };
    },

    watch: {
        product() {
            const { permissions } = this.product;

            this.allowDoctor = permissions?.includes('doctor');
            this.allowNutrition = permissions?.includes('nutrition');
            this.allowPharmacist = permissions?.includes('pharmacist');
            this.allowNurse = permissions?.includes('nurse');
        }
    },

    methods: {
        getPermissions() {
            const permissions = [];

            if (this.allowDoctor) {
                permissions.push('doctor');
            }

            if (this.allowPharmacist) {
                permissions.push('pharmacist');
            }

            if (this.allowNutrition) {
                permissions.push('nutrition');
            }

            if (this.allowNurse) {
                permissions.push('nurse');
            }

            return permissions;
        },

        updatePermissions() {
            this.$emit('update-permissions', this.getPermissions());
        }
    }
};
</script>
