<template>
    <div>
        <ul class="nav nav-pills navtab-bg nav-justified mb-3">
            <li v-for="type in userTypes" :key="type" class="nav-item">
                <a
                    href="#"
                    data-toggle="tab"
                    aria-expanded="false"
                    class="nav-link"
                    :class="{
                        active: activeUserType === type
                    }"
                    @click.prevent="activeUserType = type"
                >
                    {{ type }}
                </a>
            </li>
        </ul>
        <div class="form-group col-6">
            <input
                v-model="newCheckbox.title"
                class="form-control"
                placeholder="Title"
                :class="{ 'is-invalid': $v.newCheckbox.title.$error }"
            />
        </div>
        <div class="form-group col-6">
            <b-form-select
                v-model="newCheckbox.type"
                :options="['PDF', 'Video']"
                placeholder="Type"
                @change="typeChangeHandler"
            />
        </div>
        <div class="form-group col-6">
            <multiselect
                v-model="newCheckbox.files"
                :options="checkboxFiles"
                :class="{ 'is-invalid': $v.newCheckbox.files.$error }"
                label="name"
                track-by="name"
                :multiple="true"
                placeholder="Files"
            />
        </div>
        <div class="form-group col-12 col-lg-6">
            <button
                class="btn btn-danger waves-effect waves-light"
                data-animation="fadein"
                data-plugin="custommodal"
                data-overlaycolor="#38414a"
                @click="addCheckboxHandler"
            >
                <i class="mdi mdi-plus-circle mr-1" />
                Add Checkbox
            </button>
        </div>

        <div class="table-responsive my-5">
            <h5 class="mb-3 text-uppercase"> Checkboxes: </h5>
            <table
                id="checkboxes-datatable"
                class="table table-centered table-striped text-center"
            >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="checkbox in checkboxesToDisplay"
                        :key="checkbox.index"
                    >
                        <td>{{ checkbox.title }}</td>
                        <td @click.stop>
                            <a
                                href="#"
                                class="action-icon"
                                @click.prevent="showCheckboxEditModal(checkbox)"
                            >
                                <i class="fas fa-pen mr-1" />
                            </a>
                            <a
                                href="#"
                                class="action-icon"
                                @click.prevent="
                                    showCheckboxDeleteModal(checkbox)
                                "
                            >
                                <i class="far fa-trash-alt" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <modal-checkbox-edit
            :checkbox="checkboxToUpdate"
            :product="product"
            @hidden="hiddenModalCheckboxEditHandler"
            @update-checkbox="updateCheckboxHandler"
        />

        <confirm-modal
            title="Do you want to delete this checkbox?"
            @agree="deleteCheckboxHandler"
            @disagree="indexToDelete = null"
        />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';
import ConfirmModal from '@components/elements/ConfirmModal';
import ModalCheckboxEdit from './ModalCheckboxEdit';

export default {
    components: {
        Multiselect,
        ConfirmModal,
        ModalCheckboxEdit
    },

    props: {
        product: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    validations: {
        newCheckbox: {
            title: {
                required
            },
            files: {
                required
            }
        }
    },

    data() {
        return {
            indexToDelete: null,
            checkboxToUpdate: null,
            newCheckbox: {
                type: 'PDF'
            },
            userTypes: ['doctor', 'nurse', 'patient'],
            activeUserType: 'doctor'
        };
    },

    computed: {
        checkboxFiles() {
            const files =
                this.newCheckbox.type === 'PDF'
                    ? this.product.pdfs
                    : this.product.videos;

            return (
                files?.filter(file => file.type === this.activeUserType) || []
            );
        },

        checkboxesToDisplay() {
            return this.product.checkboxes
                ?.map((checkbox, index) => ({ ...checkbox, index }))
                .filter(checkbox => checkbox.userType === this.activeUserType);
        }
    },

    methods: {
        hiddenModalCheckboxEditHandler() {
            this.checkboxToUpdate = null;
        },

        showCheckboxDeleteModal(item) {
            this.indexToDelete = item.index;

            this.$bvModal.show('confirmModal');
        },

        showCheckboxEditModal(checkbox) {
            this.checkboxToUpdate = checkbox;

            this.$bvModal.show('modalCheckboxEdit');
        },

        addCheckboxHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('add-checkbox', {
                ...this.newCheckbox,
                userType: this.activeUserType
            });

            this.newCheckbox = { type: 'PDF' };

            this.$v.$reset();
        },

        updateCheckboxHandler(data) {
            this.$emit('update-checkbox', data);
        },

        deleteCheckboxHandler() {
            this.$emit('delete-checkbox', this.indexToDelete);
        },

        typeChangeHandler() {
            if (!this.newCheckbox.files) {
                return;
            }

            this.newCheckbox.files = [];
        }
    }
};
</script>
