<template>
    <div>
        <ul class="nav nav-pills navtab-bg nav-justified mb-3">
            <li v-for="type in types" :key="type" class="nav-item">
                <a
                    href="#"
                    data-toggle="tab"
                    aria-expanded="false"
                    class="nav-link"
                    :class="{
                        active: activeType === type
                    }"
                    @click.prevent="activeType = type"
                >
                    {{ type }}
                </a>
            </li>
        </ul>
        <div v-if="videosToUpdate" class="table-responsive">
            <products-draggable-list
                :key="videosToUpdate.length + '_' + productId"
                :show-color-box="true"
                :items="videosToUpdate"
                :item-type="'video'"
                @element-moved="onElementMove"
                @delete-element="onDeleteElement"
            />
        </div>
        <div class="form-group col-12">
            <label for="videoName">Video Name</label>
            <input
                id="videoName"
                v-model="video.name"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': $v.video.name.$error
                }"
            />
        </div>
        <div class="form-group col-12">
            <label for="videoName">Video Link</label>
            <input
                id="videoLink"
                v-model="video.url"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': $v.video.url.$error
                }"
            />
        </div>
        <div class="d-flex flex-wrap">
            <div class="form-group col-12 d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="addVideoHandler"
                >
                    Add Video
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import ProductsDraggableList from './ProductsDraggableList';

export default {
    components: {
        ProductsDraggableList
    },

    props: {
        productId: {
            type: String,
            required: false,
            default: ''
        },
        videos: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    validations: {
        video: {
            name: {
                required
            },
            url: {
                required
            }
        }
    },

    data() {
        return {
            videosToUpdate: [],
            video: {},
            types: ['doctor', 'nurse', 'patient'],
            activeType: 'doctor'
        };
    },

    watch: {
        videos() {
            this.videosToUpdate = [
                ...this.videos.filter(video => video.type === this.activeType)
            ];
        },
        activeType() {
            this.videosToUpdate = [
                ...this.videos.filter(video => video.type === this.activeType)
            ];
        }
    },

    mounted() {
        this.videosToUpdate = [...this.videos];
    },

    methods: {
        ...mapActions({
            addVideo: 'products/addVideo',
            deleteVideo: 'products/deleteVideo',
            changeVideoOrder: 'products/changeVideoOrder'
        }),

        async addVideoHandler() {
            try {
                this.$v.video.$touch();

                if (this.$v.video.$invalid) {
                    return;
                }

                const newVideo = await this.addVideo({
                    productId: this.productId,
                    video: { ...this.video, type: this.activeType }
                });

                this.video = {};
                this.$v.video.$reset();
                this.videosToUpdate = [...this.videosToUpdate, newVideo];

                this.$emit('update-videos', [...this.videos, newVideo]);

                this.$bvToast.toast('Video added successfully!', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                let message = '';

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                } else {
                    message = 'Something went wrong!';
                }

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async onElementMove(items) {
            const orderedArray = items.map((item, index) => {
                return { ...item, order: index };
            });

            try {
                await this.changeVideoOrder({
                    orderedVideos: orderedArray,
                    productId: this.productId
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async onDeleteElement(id) {
            try {
                await this.deleteVideo(id);

                const index = this.videosToUpdate.findIndex(
                    item => item.id === id
                );

                if (index !== -1) {
                    this.videosToUpdate.splice(index, 1);
                }

                this.$emit(
                    'update-videos',
                    this.videos.filter(video => video.id !== id)
                );
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
</script>
