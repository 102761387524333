<template>
    <div>
        <table
            id="videos-datatable"
            class="table table-centered table-striped text-center mb-3 mt-3"
        >
            <thead class="d-flex col-12">
                <tr class="d-flex col-12">
                    <th class="col-2"> Order </th>
                    <th
                        v-for="(field, index) in fields"
                        :key="index"
                        :class="field.class"
                    >
                        {{ field.label }}
                    </th>
                    <th class="col-2"> Actions </th>
                </tr>
            </thead>
            <tbody>
                <draggable
                    class="col-12"
                    :list="draggableElements"
                    ghost-class="ghost"
                    v-bind="dragOptions"
                    @end="onElementsMoved"
                    @start="drag = true"
                >
                    <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                        class="col-12 d-flex flex-wrap"
                        style="display: block"
                    >
                        <tr
                            v-for="(item, index) in draggableElements"
                            :key="item.id"
                            class="col-12 d-flex"
                            style="cursor: grab"
                        >
                            <td class="col-2">
                                {{ index + 1 }}
                            </td>
                            <td
                                v-for="(field, fieldIndex) in fields"
                                :key="fieldIndex"
                                :class="field.class"
                            >
                                {{ item[field.value] }}
                            </td>
                            <td class="col-2">
                                <a
                                    href="#"
                                    class="action-icon mr-3"
                                    @click.prevent="showDeleteModal(item)"
                                >
                                    <i class="far fa-trash-alt" />
                                </a>
                                <a
                                    href="#"
                                    class="action-icon"
                                    @click.prevent="openLink(item)"
                                >
                                    <i class="fas fa-external-link-alt"></i>
                                </a>
                            </td>
                        </tr>
                    </transition-group>
                </draggable>
            </tbody>
        </table>

        <delete-modal
            :id="`delete_modal_${itemType}`"
            :title="'Do you want to delete this item?'"
            @agree="onDeleteConfirm"
            @disagree="idToDelete = null"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import config from '@config';
import DeleteModal from '@components/elements/DeleteModal';

export default {
    components: {
        Draggable,
        DeleteModal
    },

    props: {
        items: {
            type: [Array, Object],
            required: true
        },

        itemType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            drag: true,
            idToDelete: null
        };
    },

    computed: {
        ...mapGetters({ loggedUser: 'auth/currentUser' }),

        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
                filter: '.is-not-draggable'
            };
        },

        draggableElements() {
            return [...this.items];
        },

        fields() {
            const fields = [
                {
                    label: 'Name',
                    value: 'name',
                    class: 'col-8'
                }
            ];

            if (
                this.itemType === 'pdf' &&
                this.loggedUser &&
                this.loggedUser.role === 'admin'
            ) {
                fields[0].class = 'col-6';
                fields.push({
                    label: 'Expiration date',
                    value: 'expiration_date',
                    class: 'col-2'
                });
            }

            return fields;
        }
    },

    methods: {
        onElementsMoved() {
            this.drag = false;

            const newOrdersArray = [...this.draggableElements];

            this.$emit('element-moved', newOrdersArray, this.itemType);
        },

        onDeleteConfirm() {
            this.$emit('delete-element', this.idToDelete, this.itemType);
        },

        showDeleteModal(item) {
            this.idToDelete = item.id;
            this.$bvModal.show(`delete_modal_${this.itemType}`);
        },

        openLink(item) {
            if (!item.url.startsWith('http')) {
                window.open(`${config.mediaUrl}/${item.url}`, '_blank');

                return;
            }

            window.open(item.url, '_blank');
        }
    }
};
</script>
